import { animated } from 'react-spring'
import { VisuallyHidden } from '/features/accessibility/VisuallyHidden'

import { Icon } from '/features/buildingBlocks/Icon'

import styles from './Button.css'

/** @type {'button' | 'submit'} */
const defaultType = 'button'

export function ButtonRoundedIconSlider({ dataX, ariaLabel, disabled = undefined, onClick = undefined, href = undefined, layoutClassName = undefined, icon }) {
  return (
    <button
      className={cx(styles.componentRoundedIconSlider,  disabled && styles.isDisabled, layoutClassName)}
      data-x={dataX}
      aria-label={ariaLabel}
      {...{ onClick }}
    >
      <Icon {...{ icon }} />
      <VisuallyHidden>
        {ariaLabel}
      </VisuallyHidden>
    </button>
  )
}

export function Button({ children, dataX, type = defaultType, disabled = undefined, onClick = undefined, href = undefined, download = undefined, layoutClassName = undefined }) {
  const Base = href ? ABase : ButtonBase

  return (
    <Base
      className={cx(styles.component, styles.isPadded, disabled && styles.isDisabled, layoutClassName)}
      {...{ type, dataX, disabled, onClick, href, download }}
    >
      {children}
    </Base>
  )
}

export function ButtonGhost({ children, dataX, disabled = undefined, onClick = undefined, href = undefined, layoutClassName = undefined, animation = undefined }) {
  const Base = href ? ABase : ButtonBase

  return (
    <Base
      className={cx(styles.componentGhost, styles.isPadded, layoutClassName)}
      {...{ dataX, disabled, onClick, href, animation }}
    >
      {children}
    </Base>
  )
}

export function ButtonGhostIcon({ dataX, ariaLabel, disabled = undefined, onClick = undefined, href = undefined, layoutClassName = undefined, icon }) {
  return (
    <ButtonGhostIconBase
      className={cx(styles.componentGhostIcon, layoutClassName)}
      {...{ icon, ariaLabel, dataX, disabled, onClick, href }}
    />
  )
}

export function ButtonRoundedIcon({ dataX, ariaLabel, disabled = undefined, onClick = undefined, href = undefined, layoutClassName = undefined, icon }) {
  const Base = href ? ABase : ButtonBase

  return (
    <Base
      className={cx(styles.componentRoundedIcon, styles.isRounded, layoutClassName)}
      {...{ dataX, ariaLabel, disabled, onClick, href }}
    >
      <div className={cx(styles.iconInner, styles.isPrimary)}>
        <Icon {...{ icon }} />
      </div>
    </Base>
  )
}

export function ButtonGhostRoundedIcon({ dataX, ariaLabel, dataXContext = undefined, disabled = undefined, onClick = undefined, href = undefined, layoutClassName = undefined, icon }) {
  return (
    <ButtonGhostIconBase
      className={cx(styles.componentGhostRoundedIcon, styles.isRounded, layoutClassName)}
      {...{ icon, ariaLabel, dataX, disabled, onClick, href, dataXContext }}
    />
  )
}

function ButtonGhostIconBase({
  icon,
  dataX,
  ariaLabel,
  dataXContext = undefined,
  disabled = undefined,
  onClick = undefined,
  href = undefined,
  className = undefined,
  layoutClassName = undefined
}) {
  const Base = href ? ABase : ButtonBase

  return (
    <Base
      className={cx(styles.componentGhostIconBase, className, layoutClassName)}
      {...{ dataX, dataXContext, disabled, ariaLabel, onClick, href }}
    >
      <div className={styles.iconInner}>
        <Icon {...{ icon }} />
      </div>
    </Base>
  )
}

export function ButtonIcon({ dataX, ariaLabel, disabled = undefined, onClick = undefined, href = undefined, layoutClassName = undefined, icon }) {
  const Base = href ? ABase : ButtonBase

  return (
    <Base
      className={cx(styles.componentIcon, styles.isPadded, layoutClassName)}
      {...{ dataX, ariaLabel, disabled, onClick, href }}
    >
      <Icon {...{ icon }} />
    </Base>
  )
}

function ABase({ className, href, dataX, dataXContext = undefined, ariaLabel = undefined, children, download = undefined, animation = undefined }) {
  const protocols = ['http', 'https', 'tel', 'mailto']
  const hasProtocol = protocols.some(x => href.startsWith(x))
  const target = hasProtocol ? '_blank' : '_self'
  const rel = hasProtocol ? 'noopener noreferrer' : null
  const ElementBase = animation ? animated.a : 'a'

  return (
    <ElementBase
      className={cx(styles.componentABase, className)}
      data-x={dataX}
      data-x-context={dataXContext}
      style={animation}
      aria-label={ariaLabel}
      {...{ href, rel, target }}
      {...{ download }}
    >
      {children}
    </ElementBase>
  )
}

function ButtonBase({ className, dataX, children, disabled, type = defaultType, onClick, dataXContext = undefined, ariaLabel = undefined, animation = undefined }) {
  const ElementBase = animation ? animated.button : 'button'

  return (
    <ElementBase
      data-x={dataX}
      data-x-context={dataXContext}
      aria-label={ariaLabel}
      className={cx(styles.componentBase, disabled && styles.isDisabled, className)}
      style={animation}
      {...{ onClick, disabled, type }}
    >
      {children}
    </ElementBase>
  )
}
