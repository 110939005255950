import { HeadingXs } from '/features/buildingBlocks/Heading'
import { ButtonGhost } from '/features/buildingBlocks/Button'
import { TextXs } from '/features/buildingBlocks/Text'

import styles from './CardWithoutImage.css'

export function CardWithoutImage({ type, title, href, description, label, dataX, layoutClassName }) {
  return (
    <div className={cx(styles.component, layoutClassName)}>
      <span className={styles.type}>{type}</span>
      <HeadingXs h='4' layoutClassName={styles.titleLayout} {...{ title }} />
      {description && <TextXs layoutClassName={styles.descriptionLayout} text={description} />}
      {href && label && (
        <ButtonGhost
          layoutClassName={styles.buttonLayout}
          {...{ href, dataX }}
        >
          {label}
        </ButtonGhost>
      )}
    </div>
  )
}
