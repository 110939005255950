import { animated } from 'react-spring'
import styles from './Text.css'

export function TextXs({ text, layoutClassName = undefined }) {
  return (
    <TextBase
      className={cx(styles.componentXs, layoutClassName)}
      {...{ text }}
    />
  )
}

export function TextAccentSmCentered({ text, layoutClassName = undefined, animation }) {
  return (
    <TextBase
      className={cx(styles.componentAccentSmCentered, layoutClassName)}
      {...{ text, animation }}
    />
  )
}

export function TextSm({ text, layoutClassName = undefined }) {
  return (
    <TextBase
      className={cx(styles.componentSm, layoutClassName)}
      {...{ text }}
    />
  )
}

export function TextMd({ text, layoutClassName = undefined }) {
  return (
    <TextBase
      className={cx(styles.componentMd, layoutClassName)}
      {...{ text }}
    />
  )
}

function TextBase({ text, className, animation = undefined }) {
  const ElementBase = animation ? animated.p : 'p'
  return (
    <ElementBase className={cx(styles.componentBase, className)} style={animation}>
      {text}
    </ElementBase>
  )
}
