import { ButtonGhost } from '/features/buildingBlocks/Button'
import { ImageCover } from '/features/buildingBlocks/Image'
import { useMediaQuery } from '@kaliber/use-media-query'
import { HeadingMd, HeadingSm } from './Heading'

import mediaStyles from '/cssGlobal/media.css'
import styles from './Card.css'

export function CardVertical({ title, type, label, href, image, dataX, description = undefined, layoutClassName = undefined, heading = undefined }) {
  return (
    <Base
      className={cx(styles.componentVertical, styles.cardVerticalBase, layoutClassName)}
      {...{ title, type, label, href, image, description, heading, dataX }}
    />
  )
}

export function CardVerticalReversed({ title, type, label, href, image, dataX, description = undefined, layoutClassName = undefined, heading = undefined }) {
  return (
    <Base
      className={cx(styles.componentVerticalReversed, styles.cardVerticalBase, layoutClassName, styles.isReversed)}
      {...{ title, type, label, href, image, description, heading, dataX }}
    />
  )
}

export function CardHorizontal({ title, type, label, href, image, dataX, heading = undefined }) {
  return (
    <Base className={styles.componentHorizontal} {...{ title, type, label, href, image, heading, dataX }} />
  )
}

const headingDefaults = {
  h: '3',
  headingMobile: HeadingSm,
  headingDesktop: HeadingMd,
}

function Base({ title, type, label, href, image, dataX, className, description = undefined, heading = headingDefaults }) {
  const isMobile = useMediaQuery(mediaStyles.viewportMd) === false
  const Heading = isMobile
    ? heading.headingMobile
    : heading.headingDesktop

  return (
    <div className={cx(styles.componentBase, className)}>
      <div className={styles.content}>
        <span className={styles.type}>{type}</span>
        <Heading h={heading.h} layoutClassName={styles.titleLayout} {...{ title }} />
        {description && <p className={styles.description}>{description}</p>}
        {href && label && (
          <ButtonGhost
            layoutClassName={styles.buttonLayout}
            {...{ href, dataX }}
          >
            {label}
          </ButtonGhost>
        )}
      </div>

      {image?.asset?._ref && (
        <div className={styles.coverImage}>
          <ImageCover
            sizes={`100cqw`}
            aspectRatio={1}
            layoutClassName={styles.imageLayout}
            {...{ image }}
          />
        </div>
      )}
    </div>
  )
}
